<template>
    <div class="announcement-bar-wrapper" v-if="show">
        <div class="announcement-bar">
            <div class="announcement-bar-icon"></div>
            <div class="announcement-bar-text">
                <h3>{{content}}</h3>
            </div>
            <div class="announcement-bar-cta">
                <a v-if="cta && cta.url" :target="cta.target" class="button-cta" :href="cta.url">{{ cta.title }}</a>
                <div @click="dismissBar" class="announcement-dimiss">X</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import API from '@/includes/API';
import { onMounted } from 'vue';
const show = ref( null );
const cta = ref({});
const content = ref( localStorage.getItem('announcement_bar_content') || "" );
const dismissBar = ()=>{
    show.value = false;
    localStorage.setItem('show_announcement_bar',false);
}
onMounted(async () => {
    try{
        const announcement = await API.get('/announcement-bar');

        let local = false;//localStorage.getItem('show_announcement_bar');
        if( local ){
            show.value = false;
        }
        if( announcement && announcement.enable ){
            if(  show.value == null ){
                show.value = announcement.enable
            }
            if( content.value != announcement.content ){
                localStorage.setItem('announcement_bar_content',announcement.content);
                content.value = announcement.content
                show.value = true;
            }
            cta.value = announcement.call_to_action

        }
    }catch(e){

    }
})
</script>

<style lang="scss" scoped>
.announcement-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 50px; */
    background: var(--filmstro-red);
    z-index: 9999999999999;
}

.announcement-bar {
    display: grid;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 14px;
    grid-template-columns: 200px 1fr 200px;
}

.announcement-bar-text {
    text-align: center;
}

.announcement-bar-cta {
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

a.button-cta {
    background: var(--filmstro-yellow);
    padding: 5px 10px;
    border-radius: 4px;
    color: black;
}

.announcement-dimiss {
    padding: 3px;
    cursor: pointer;
    background: black;
    width: 20px;
    height: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>